<dp-header2>{{ taskName }}</dp-header2>
<div class="sub-header">
  <div class="no-icon">
    <dp-header5>Move task |</dp-header5>
    <dp-support-type> Copy link</dp-support-type>
    <dp-button
      type="icon"
      icon="clipboard"
      [noPadding]="true"
      [fullWidth]="false"
      (click)="copyField()"
    ></dp-button>
  </div>
</div>

<div class="row">
  <div class="col-12 mb-lg-4">
    <ul>
      <li>
        <dp-detail-type>Source</dp-detail-type>
        <dp-lead-type
          *ngIf="taskSources.length === 1 && !loading; else multipleSources"
        >
          <dp-icon
            type="{{
              setIconType(taskSources[0]?.orgUnitType?.toString() ?? '')
            }}"
          ></dp-icon>
          {{ taskSources[0].leafName }}</dp-lead-type
        >
        <ng-template #multipleSources>
          <dp-autocomplete
            *ngIf="isMultipleSources() && !loading"
            [options]="taskSources"
            optionField="leafName"
            outputField="leafNodeId"
            label="Multiple"
            (changed)="onChangeSource($event)"
          ></dp-autocomplete>
        </ng-template>
      </li>
      <li class="destination" id="destination">
        <dp-detail-type>Destination</dp-detail-type>

        <dp-lead-type
          *ngIf="
            taskDestinations.length === 1 && !loading;
            else multipleDestinations
          "
        >
          <dp-icon
            type="{{
              setIconType(taskDestinations[0]?.orgUnitType?.toString() ?? '')
            }}"
          ></dp-icon>
          {{ taskDestinations[0].leafName }}</dp-lead-type
        >

        <ng-template #multipleDestinations>
          <dp-autocomplete
            *ngIf="
              !loading &&
                isMultipleDestinations() &&
                (!allPackagesWithMultipleDestinations ||
                  isChangedDestination) &&
                isNotOfficeDestination();
              else notTemplatingDest
            "
            [options]="taskDestinations"
            optionField="leafName"
            outputField="leadNodeId"
            label="Multiple"
            (changed)="onChangeDestination($event)"
          ></dp-autocomplete>
        </ng-template>
        <ng-template #notTemplatingDest>
          <div *ngIf="taskDestinations.length !== 0 && !loading">
            <dp-icon
              type="{{
                setIconType(taskDestinations[0].orgUnitType?.toString() ?? '')
              }}"
              class="dp-mr-2"
            ></dp-icon>
            <dp-lead-type>Multiple Destinations</dp-lead-type>
          </div>
        </ng-template>
      </li>
    </ul>
  </div>
</div>
