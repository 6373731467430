import { Component, Input, OnInit, HostListener } from '@angular/core';
import {
  GeneralInformationDto,
  LeafNode,
} from '@shareview/libs/generated/shareview-api';
import { copyField } from '@shareview/shared/utilis';
import { ShareViewMoveService } from '@shareview/shareview/data';
import { getShareviewKey } from '@shareview/shared/utilis';
import { setIconType } from '../transmission-list/package-card/package-card-utils';
import { FilterSourceDest } from './utils/filter-source-dest.service';
import {
  filterByClaimed,
  sortByShipNameAscending,
} from './utils/general-information-utils';

@Component({
  selector: 'shareview-move-general-information',
  templateUrl: './general-information.component.html',
  styleUrls: ['./general-information.component.scss'],
})
export class GeneralInformationComponent implements OnInit {
  @Input() generalInformation: GeneralInformationDto;

  shareviewKey!: string;
  taskName!: string;
  shareViewUrl!: string;
  taskSources: LeafNode[] = [];
  taskDestinations: LeafNode[] = [];
  setIconType = setIconType;
  isChangedDestination = false;
  allPackagesWithMultipleDestinations: boolean;
  loading = false;

  constructor(
    private shareViewMoveService: ShareViewMoveService,
    private filterSourceDest: FilterSourceDest
  ) {}

  ngOnInit() {
    this.shareviewKey = getShareviewKey();
    this.shareViewMoveService
      .incrementMoveCounter(this.shareviewKey)
      .pipe()
      .subscribe();

    this.taskName = this.generalInformation.taskName!;
    this.shareViewUrl = this.generalInformation.url!;

    if (this.generalInformation.sources) {
      this.taskSources = this.generalInformation.sources
        .filter(filterByClaimed)
        .sort(sortByShipNameAscending);
    }

    if (this.generalInformation.destinations) {
      this.taskDestinations = this.generalInformation.destinations
        .filter(filterByClaimed)
        .sort(sortByShipNameAscending);
    }

    if (this.isMultipleDestinations()) {
      this.loading = true;
      this.filterSourceDest.isAllPackagesWithMultipleDestinations$.subscribe(
        (response) => {
          this.allPackagesWithMultipleDestinations = response;
          this.loading = false;
        }
      );
    }
  }

  copyField() {
    if (this.shareViewUrl) copyField(this.shareViewUrl);
  }

  isMultipleSources(): boolean {
    return this.taskSources.length > 1;
  }

  isMultipleDestinations(): boolean {
    return this.taskDestinations.length > 1;
  }

  isNotOfficeDestination(): boolean {
    return (
      this.generalInformation.destinations[0].orgUnitType.toString() !==
        'Office' &&
      this.generalInformation.destinations[0].orgUnitType.toString() !==
        'OfficeGroup'
    );
  }

  onChangeSource(source: any) {
    this.filterSourceDest.triggerFilterSource(source);
  }

  onChangeDestination(destination: any) {
    this.isChangedDestination = destination ? true : false;
    this.filterSourceDest.triggerFilterDestinations(destination);
  }

  @HostListener('window:resize', ['$event'])
  onResize() {
    if (window.innerWidth > 1200) {
      document.getElementById('destination')!.style.padding = '1.5rem 1.5rem';
    } else {
      document.getElementById('destination')!.style.padding = '0 0 2rem';
    }
  }
}
