/**
 * ShareView - public API
 * Public facing API for Dualog Drive.
 *
 * The version of the OpenAPI document: v1
 * Contact: support@dualog.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type ClaimStatus = 'OK' | 'NoInstallations' | 'NoClaims' | 'NoConfiguration';

export const ClaimStatus = {
    Ok: 'OK' as ClaimStatus,
    NoInstallations: 'NoInstallations' as ClaimStatus,
    NoClaims: 'NoClaims' as ClaimStatus,
    NoConfiguration: 'NoConfiguration' as ClaimStatus
};

