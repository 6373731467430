import { Injectable } from '@angular/core';
import { Observable, ReplaySubject, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class FilterSourceDest {
  private filterSource = new Subject<void>();
  private filterDestinations = new Subject<void>();
  private isAllPackagesWithMultipleDestinations = new ReplaySubject<boolean>(1);

  filterSource$ = this.filterSource.asObservable();
  filterDestinations$ = this.filterDestinations.asObservable();
  isAllPackagesWithMultipleDestinations$ =
    this.isAllPackagesWithMultipleDestinations.asObservable();

  triggerFilterSource(source: any) {
    this.filterSource.next(source);
  }

  triggerFilterDestinations(destination: any) {
    this.filterDestinations.next(destination);
  }

  triggerIsAllPackagesWithMultipleDestinations(result: any) {
    this.isAllPackagesWithMultipleDestinations.next(result);
  }
}
